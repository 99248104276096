<!--
  Copyright (C) 2022 - Jeci SARL - https://jeci.fr

  This program is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as
  published by the Free Software Foundation, either version 3 of the
  License, or (at your option) any later version.

  This program is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with this program.  If not, see https://www.gnu.org/licenses/agpl-3.0.html.
-->

<template>
  <div class="layout-topbar">
    <Button
      icon="ri-layout-left-line"
      class="ml-2 mr-6"
      @click="onMenuToggle"
      rounded
      outlined
      raised
      severity="secondary"
      aria-label="Menu"
      v-if="displayMenuButton"
    />
    <a href="/portail" class="layout-topbar-logo">
      <div id="topBarLogo" />
    </a>
    <div
      class="p-link layout-topbar-menu-button layout-topbar-button mobile_zone ml-auto"
      v-if="displaySearchButton"
    >
      <button class="p-link layout-topbar-button" @click="searchClickMobile">
        <i class="ri-search-line" />
      </button>
    </div>
    <button
      class="p-link layout-topbar-menu-button layout-topbar-button"
      v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        enterActiveClass: 'scalein',
        leaveToClass: 'hidden',
        leaveActiveClass: 'fadeout',
        hideOnOutsideClick: true,
      }"
    >
      <i class="ri-more-2-line" />
    </button>
    <ul class="layout-topbar-menu lg:flex origin-top hidden">
      <li v-if="displaySearchButton">
        <div class="classic_zone">
          <button
            class="p-link layout-topbar-button"
            v-if="!searchExpand"
            @click="searchClick"
          >
            <i class="ri-search-line" />
          </button>
          <IconField v-else iconPosition="left">
            <InputIcon class="ri-search-line" />
            <InputText
              v-model="searchInput"
              ref="search"
              type="text"
              placeholder="Search"
              @focusout="closeExpand"
              @keyup.enter="search"
            />
          </IconField>
        </div>
      </li>
      <li>
        <Menu ref="menuOptions" :model="menuOptionsItems" :popup="true">
          <template #item="{ label, item, props }">
            <router-link
              v-if="item.route"
              v-slot="routerProps"
              :to="item.route"
              custom
            >
              <a :href="routerProps.href" v-bind="props.action">
                <span v-bind="props.icon" />
                <span v-bind="props.label">{{ label }}</span>
              </a>
            </router-link>
            <a v-else :href="item.url" v-bind="props.action">
              <span v-bind="props.icon" />
              <span v-bind="props.label">{{ label }}</span>
            </a>
          </template>
        </Menu>
        <button
          class="p-link layout-topbar-button"
          @click="toggleMenuOptions($event)"
          v-tooltip.bottom="$t('settingsMenu')"
        >
          <i class="ri-settings-3-line" />
          <span>{{ $t("settingsMenu") }}</span>
        </button>
      </li>
      <li>
        <button
          class="p-link layout-topbar-button"
          v-tooltip.bottom="this.displayName"
          @click="onButtonProfile"
        >
          <i class="ri-user-3-line" />
          <span>{{ displayName }}</span>
        </button>
      </li>
      <li>
        <button
          class="p-link layout-topbar-button"
          @click="onButtonLogout"
          v-tooltip.bottom="this.$t('logoutButton')"
        >
          <i class="ri-logout-circle-r-line" />
          <span>{{ $t("logoutButton") }}</span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useConfigStore, useUserStore } from "@pristy/pristy-libvue";
import { useRouter } from "vue-router";

export default {
  router: null,
  name: "AppTopBar",
  props: {
    displayMenuButton: {
      type: Boolean,
      default: true,
    },
    displaySearchButton: {
      type: Boolean,
      default: true,
    },
    displaySettingButton: {
      type: Boolean,
      default: true,
    },
    displayUserButton: {
      type: Boolean,
      default: true,
    },
    displayLogoutButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      menuOptionsItems: [],
      searchInput: null,
      searchExpand: false,
    };
  },
  created() {
    this.router = useRouter();
  },
  methods: {
    searchClickMobile() {
      window.location = `${this.ESPACES_HOST}/espaces/recherche`;
    },
    search() {
      window.location = `${this.ESPACES_HOST}/espaces/recherche?search=${this.searchInput}`;
    },
    closeExpand() {
      this.searchExpand = false;
      this.searchInput = "";
    },
    searchClick() {
      this.searchExpand = true;
      this.$nextTick(() => {
        this.$refs.search.$el.focus();
      });
    },
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
    toggleMenuOptions(event) {
      this.menuOptionsItems = [
        {
          label: this.$t("trashcan.name"),
          icon: "ri-delete-bin-line",
          command: () => {
            this.router.push({
              name: "corbeille",
            });
          },
        },
      ];
      this.$refs.menuOptions.toggle(event);
    },
    onButtonProfile() {
      this.router.push({
        name: "profile",
      });
    },
    onButtonLogout() {
      const userStore = useUserStore();
      userStore.logout();
      if (this.AUTH === "basic") {
        this.router.push({
          name: "login",
        });
      }
    },
  },
  computed: {
    ...mapState(useUserStore, ["displayName"]),
    ...mapState(useConfigStore, ["AUTH", "APP_ROOT", "ESPACES_HOST"]),
    darkTheme() {
      return this.$appState.darkTheme;
    },
  },
};
</script>
<style scoped>
#topBarLogo {
  background-position: center;
  background-size: contain;
  width: 14rem;
  height: 3rem;
  background-repeat: no-repeat;
  background-image: url("/images/pristy/logo-pristy-app-portail-fdtr.png");
}
</style>
