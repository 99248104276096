<!--
  Copyright (C) 2022 - Jeci SARL - https://jeci.fr

  This program is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as
  published by the Free Software Foundation, either version 3 of the
  License, or (at your option) any later version.

  This program is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with this program.  If not, see https://www.gnu.org/licenses/agpl-3.0.html.

-->

<template>
  <div class="layout-menu-container">
    <AppSubmenu
      :items="model"
      class="layout-menu"
      :root="true"
      @menuitem-click="onMenuItemClick"
      @keydown="onKeyDown"
    />
    <a href="https://pristy.fr" class="block mt-5">
      <div id="menuLogo" class="menu-logo" />
    </a>
  </div>
</template>

<script>
import AppSubmenu from "./AppSubmenu.vue";
import { useConfigStore } from "@pristy/pristy-libvue";

export default {
  config: null,
  created() {
    this.config = useConfigStore();
  },
  props: {
    model: Array,
  },
  methods: {
    onMenuItemClick(event) {
      this.$emit("menuitem-click", event);
    },
    onKeyDown(event) {
      const nodeElement = event.target;
      if (event.code === "Enter" || event.code === "Space") {
        nodeElement.click();
        event.preventDefault();
      }
    },
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    },
  },
  components: {
    AppSubmenu,
  },
};
</script>
<style>
#menuLogo {
  background-position: center;
  background-size: contain;
  width: 10rem;
  height: 10rem;
  background-repeat: no-repeat;
  background-image: url("/images/pristy/logo-pristy-square.png");
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
