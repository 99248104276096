import { nextTick } from "vue";
import { createI18n } from "vue-i18n";
import { messages as libMessages } from "@pristy/pristy-libvue";
import { numberFormats, datetimeFormats } from "/src/locales/index.js";

export const SUPPORT_LOCALES = ["en", "fr"];
export const i18n = setupI18n();
import axios from "axios";

export function setupI18n() {
  const i18n = createI18n({
    locale: "fr",
    fallbackLocale: "fr",
    datetimeFormats,
    numberFormats,
    silentTranslationWarn: true,
    silentFallbackWarn: true,
  });
  loadLocaleMessages(i18n, "fr");
  setI18nLanguage(i18n, "fr");
  return i18n;
}

export function setI18nLanguage(i18n, locale) {
  if (i18n.mode === "legacy") {
    i18n.global.locale = locale;
  } else {
    i18n.global.locale.value = locale;
  }
  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  document.querySelector("html").setAttribute("lang", locale);
}

export async function loadLocaleMessages(i18n, locale) {
  if (locale.indexOf("-") > 0) {
    locale = locale.substring(0, locale.indexOf("-"));
  }
  if (!SUPPORT_LOCALES.includes(locale)) {
    locale = "en";
  }

  // load locale messages with dynamic import
  const modLocale = await import(`../locales/${locale}.json`).catch(() => {});
  const appMessages = modLocale;

  let messages = libMessages[locale]
    ? { ...libMessages[locale], ...appMessages }
    : appMessages;
  const custom = await axios
    .get(`${import.meta.env.BASE_URL}locales/${locale}.json`)
    .then((jsonlang) => {
      return jsonlang.data;
    })
    .catch(() => {});
  if (custom) {
    console.log("Custom Messages", custom);
    messages = { ...messages, ...custom };
  }
  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages);

  return nextTick();
}

export function currentLang() {
  let locale =
    localStorage?.getItem("user.language") || window?.navigator?.language;
  if (locale.indexOf("-") > 0) {
    locale = locale.substring(0, locale.indexOf("-"));
  }
  if (!SUPPORT_LOCALES.includes(locale)) {
    locale = "en";
  }

  return locale;
}

export function fieldlabel(field) {
  const locale = currentLang();
  if (
    field.labelId &&
    field.labelId.length > 0 &&
    i18n.global.te(field.labelId, locale)
  ) {
    return this.$t(field.labelId, locale);
  } else if (field.title) {
    return field.title;
  }
  return field.labelId;
}

const DIM_EN = ["o", "Ko", "Mo", "Go", "To"];
const DIM_FR = ["o", "Ko", "Mo", "Go", "To"];

export function convertBytesToSize(str) {
  if (str === null || str === undefined || str === "") {
    return "";
  }
  let value = parseInt(str);
  if (value === 0) {
    return "0";
  }

  const locale = currentLang();
  const sizes = locale === "fr" ? DIM_FR : DIM_EN;

  let i = Math.floor(Math.log(value) / Math.log(1024));
  return `${Math.round(value / Math.pow(1024, i))} ${sizes[i]}`;
}
