<!--
  Copyright (C) 2022 - Jeci SARL - https://jeci.fr

  This program is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as
  published by the Free Software Foundation, either version 3 of the
  License, or (at your option) any later version.

  This program is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with this program.  If not, see https://www.gnu.org/licenses/agpl-3.0.html.
-->

<template>
  <div class="layout-footer">
    <a id="JeciLink" href="https://jeci.fr">
      <img alt="Logo" :src="footerImage()" height="20" class="mr-2" />
      by
      <span class="font-medium ml-2">Jeci</span>
    </a>
  </div>
</template>
<script>
import { useConfigStore } from "@pristy/pristy-libvue";
export default {
  name: "AppFooter",
  config: null,
  created() {
    this.config = useConfigStore();
  },
  methods: {
    footerImage() {
      return `${this.config.APP_ROOT}images/jeci/logo-jeci.png`;
    },
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    },
  },
};
</script>
<style>
#JeciLink {
  color: var(--text-color);
}
</style>
