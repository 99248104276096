/**
 *  Copyright (C) 2022 - Jeci SARL - https://jeci.fr
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU Affero General Public License as
 *  published by the Free Software Foundation, either version 3 of the
 *  License, or (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU Affero General Public License for more details.
 *
 *  You should have received a copy of the GNU Affero General Public License
 *  along with this program.  If not, see https://www.gnu.org/licenses/agpl-3.0.html.
 */

import "primevue/resources/primevue.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "prismjs/themes/prism-coy.css";
import "./assets/styles/layout.scss";
import "remixicon/fonts/remixicon.css";
import * as Sentry from "@sentry/vue";

import { createApp, reactive } from "vue";
import router from "./router";
import AppWrapper from "./AppWrapper.vue";
import PrimeVue from "primevue/config";
import AutoComplete from "primevue/autocomplete";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Avatar from "primevue/avatar";
import AvatarGroup from "primevue/avatargroup";
import Badge from "primevue/badge";
import BadgeDirective from "primevue/badgedirective";
import Button from "primevue/button";
import BreadCrumb from "primevue/breadcrumb";
import Calendar from "primevue/calendar";
import Card from "primevue/card";
import Carousel from "primevue/carousel";
import Chart from "primevue/chart";
import CheckBox from "primevue/checkbox";
import Chip from "primevue/chip";
import Chips from "primevue/chips";
import ColorPicker from "primevue/colorpicker";
import Column from "primevue/column";
import ConfirmDialog from "primevue/confirmdialog";
import ConfirmPopup from "primevue/confirmpopup";
import ConfirmationService from "primevue/confirmationservice";
import ContextMenu from "primevue/contextmenu";
import DataTable from "primevue/datatable";
import DataView from "primevue/dataview";
import DataViewLayoutOptions from "primevue/dataviewlayoutoptions";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import DropDown from "primevue/dropdown";
import FieldSet from "primevue/fieldset";
import FileUpload from "primevue/fileupload";
import IconField from "primevue/iconfield";
import Image from "primevue/image";
import InlineMessage from "primevue/inlinemessage";
import Inplace from "primevue/inplace";
import InputIcon from "primevue/inputicon";
import InputMask from "primevue/inputmask";
import InputNumber from "primevue/inputnumber";
import InputSwitch from "primevue/inputswitch";
import InputText from "primevue/inputtext";
import Knob from "primevue/knob";
import Galleria from "primevue/galleria";
import ListBox from "primevue/listbox";
import MegaMenu from "primevue/megamenu";
import Menu from "primevue/menu";
import MenuBar from "primevue/menubar";
import Message from "primevue/message";
import MultiSelect from "primevue/multiselect";
import OrderList from "primevue/orderlist";
import OrganizationChart from "primevue/organizationchart";
import OverlayPanel from "primevue/overlaypanel";
import Paginator from "primevue/paginator";
import Panel from "primevue/panel";
import PanelMenu from "primevue/panelmenu";
import Password from "primevue/password";
import PickList from "primevue/picklist";
import ProgressBar from "primevue/progressbar";
import ProgressSpinner from "primevue/progressspinner";
import Rating from "primevue/rating";
import RadioButton from "primevue/radiobutton";
import Ripple from "primevue/ripple";
import SelectButton from "primevue/selectbutton";
import ScrollPanel from "primevue/scrollpanel";
import ScrollTop from "primevue/scrolltop";
import Slider from "primevue/slider";
import SideBar from "primevue/sidebar";
import Skeleton from "primevue/skeleton";
import SplitButton from "primevue/splitbutton";
import Splitter from "primevue/splitter";
import SplitterPanel from "primevue/splitterpanel";
import Steps from "primevue/steps";
import StyleClass from "primevue/styleclass";
import TabMenu from "primevue/tabmenu";
import Tag from "primevue/tag";
import TieredMenu from "primevue/tieredmenu";
import TextArea from "primevue/textarea";
import TimeLine from "primevue/timeline";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import ToolBar from "primevue/toolbar";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Tooltip from "primevue/tooltip";
import ToggleButton from "primevue/togglebutton";
import Tree from "primevue/tree";
import TreeSelect from "primevue/treeselect";
import TreeTable from "primevue/treetable";
import TriStateCheckbox from "primevue/tristatecheckbox";
import { createPinia } from "pinia";
import { useConfigStore } from "@pristy/pristy-libvue";
import { i18n } from "@/services/TranslationService";
import { ObserveVisibility } from "vue3-observe-visibility";

// Keycloak Vue
import VueKeyCloak from "@dsb-norge/vue-keycloak-js";

const pinia = createPinia();
const config = useConfigStore(pinia);

config.init(import.meta.env.BASE_URL).then(() => {
  const app = createApp(AppWrapper);

  if (config.SENTRY_ENABLE) {
    console.log("Sentry Enable");
    Sentry.init({
      app,
      dsn: config.SENTRY_DSN,
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          // https://auth.jeci.tech/realms/alfresco-boule/protocol/openid-connect/token
          tracePropagationTargets: [/^https:\/\/[a-z.]+\/alfresco\//],
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }

  app.use(pinia);

  app.config.globalProperties.$appState = reactive({
    theme: "saga-green",
    darkTheme: false,
  });

  app.use(PrimeVue, config.primeConfig);
  app.use(ConfirmationService);
  app.use(ToastService);
  app.use(router);
  app.use(i18n);

  if (config.AUTH !== "basic") {
    let opts = config.oidc;
    opts.init = { ...config.oidc.init, ...config.OIDC_CUSTOM };
    console.log("Auth Config opts", opts);
    app.use(VueKeyCloak, opts);
  }

  app.directive("tooltip", Tooltip);
  app.directive("ripple", Ripple);
  app.directive("badge", BadgeDirective);
  app.directive("styleclass", StyleClass);
  app.directive("observe-visibility", ObserveVisibility);

  app.component("Accordion", Accordion);
  app.component("AccordionTab", AccordionTab);
  app.component("AutoComplete", AutoComplete);
  app.component("Avatar", Avatar);
  app.component("AvatarGroup", AvatarGroup);
  app.component("Badge", Badge);
  app.component("BreadCrumb", BreadCrumb);
  // eslint-disable-next-line vue/no-reserved-component-names
  app.component("Button", Button);
  app.component("Calendar", Calendar);
  app.component("Card", Card);
  app.component("Carousel", Carousel);
  app.component("Chart", Chart);
  app.component("CheckBox", CheckBox);
  app.component("Chip", Chip);
  app.component("Chips", Chips);
  app.component("ColorPicker", ColorPicker);
  app.component("Column", Column);
  app.component("ConfirmDialog", ConfirmDialog);
  app.component("ConfirmPopup", ConfirmPopup);
  app.component("ContextMenu", ContextMenu);
  app.component("DataTable", DataTable);
  app.component("DataView", DataView);
  app.component("DataViewLayoutOptions", DataViewLayoutOptions);
  // eslint-disable-next-line vue/no-reserved-component-names
  app.component("Dialog", Dialog);
  app.component("Divider", Divider);
  app.component("DropDown", DropDown);
  app.component("FieldSet", FieldSet);
  app.component("FileUpload", FileUpload);
  app.component("IconField", IconField);
  // eslint-disable-next-line vue/no-reserved-component-names
  app.component("Image", Image);
  app.component("InlineMessage", InlineMessage);
  app.component("Inplace", Inplace);
  app.component("InputIcon", InputIcon);
  app.component("InputMask", InputMask);
  app.component("InputNumber", InputNumber);
  app.component("InputSwitch", InputSwitch);
  app.component("InputText", InputText);
  app.component("Galleria", Galleria);
  app.component("Knob", Knob);
  app.component("ListBox", ListBox);
  app.component("MegaMenu", MegaMenu);
  // eslint-disable-next-line vue/no-reserved-component-names
  app.component("Menu", Menu);
  app.component("MenuBar", MenuBar);
  app.component("Message", Message);
  app.component("MultiSelect", MultiSelect);
  app.component("OrderList", OrderList);
  app.component("OrganizationChart", OrganizationChart);
  app.component("OverlayPanel", OverlayPanel);
  app.component("Paginator", Paginator);
  app.component("Panel", Panel);
  app.component("PanelMenu", PanelMenu);
  app.component("Password", Password);
  app.component("PickList", PickList);
  app.component("ProgressBar", ProgressBar);
  app.component("ProgressSpinner", ProgressSpinner);
  app.component("RadioButton", RadioButton);
  app.component("Rating", Rating);
  app.component("SelectButton", SelectButton);
  app.component("ScrollPanel", ScrollPanel);
  app.component("ScrollTop", ScrollTop);
  app.component("Slider", Slider);
  app.component("SideBar", SideBar);
  app.component("Skeleton", Skeleton);
  app.component("SplitButton", SplitButton);
  app.component("Splitter", Splitter);
  app.component("SplitterPanel", SplitterPanel);
  app.component("Steps", Steps);
  app.component("TabMenu", TabMenu);
  app.component("TabView", TabView);
  app.component("TabPanel", TabPanel);
  app.component("Tag", Tag);
  app.component("TextArea", TextArea);
  app.component("TieredMenu", TieredMenu);
  app.component("TimeLine", TimeLine);
  app.component("Toast", Toast);
  app.component("ToolBar", ToolBar);
  app.component("ToggleButton", ToggleButton);
  app.component("Tree", Tree);
  app.component("TreeSelect", TreeSelect);
  app.component("TreeTable", TreeTable);
  app.component("TriStateCheckbox", TriStateCheckbox);

  app.mount("#app");
});
