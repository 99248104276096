<!--
  Copyright (C) 2022 - Jeci SARL - https://jeci.fr

  This program is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as
  published by the Free Software Foundation, either version 3 of the
  License, or (at your option) any later version.

  This program is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with this program.  If not, see https://www.gnu.org/licenses/agpl-3.0.html.
-->

<template>
  <div :class="containerClass" @click="onWrapperClick">
    <Toast position="bottom-right" />
    <AppTopBar @menu-toggle="onMenuToggle" v-if="shouldDisplay" />
    <div class="layout-sidebar" @click="onSidebarClick" v-if="shouldDisplay">
      <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
    </div>

    <div class="layout-main-container">
      <div class="layout-main">
        <router-view />
      </div>
      <AppFooter />
    </div>

    <transition name="layout-mask">
      <div class="layout-mask p-component-overlay" v-if="mobileMenuActive" />
    </transition>
  </div>
</template>

<script>
import AppTopBar from "./AppTopbar.vue";
import AppMenu from "./AppMenu.vue";
import AppFooter from "./AppFooter.vue";
import menuService from "@/services/MenuService";
import { useThemeStore } from "@pristy/pristy-libvue";

export default {
  emits: ["change-theme"],
  data() {
    return {
      staticMenuInactive: false,
      mobileMenuActive: false,
      menu: null,
      shouldDisplay: true,
    };
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  created() {
    this.getMenu();
    this.checkURLAndHideDiv();
    useThemeStore().initTheme();
    useThemeStore().initScale();
  },
  methods: {
    checkURLAndHideDiv() {
      const specificPath = "/portail/preview/";
      if (window.location.pathname.startsWith(specificPath)) {
        this.shouldDisplay = false;
      }
    },
    getMenu() {
      this.menu = menuService.computeMenu();
    },
    onWrapperClick() {
      if (!this.menuClick) {
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        this.staticMenuInactive = !this.staticMenuInactive;
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.mobileMenuActive = false;
      }
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi",
          ),
          " ",
        );
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        return !this.staticMenuInactive;
      }

      return true;
    },
  },
  computed: {
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-static": true,
          "layout-static-sidebar-inactive": this.staticMenuInactive,
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$primevue.config.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.config.ripple === false,
        },
      ];
    },
    logo() {
      return this.$appState.darkTheme
        ? "images/logo-white.svg"
        : "images/logo.svg";
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
  components: {
    AppTopBar,
    AppMenu,
    AppFooter,
  },
};
</script>

<style lang="scss">
@import "./App.scss";
</style>
